import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import CheckBoxCustom from '../../components/CheckBox';
import {
  addPayments,
  discounts as discountsQuery,
  loadOrders,
  order as orderQuery,
  setDiscount,
  setEveningRetrun,
  setMorningPickUp,
  updateOrderPriceOrBail,
  updateState,
} from '../../graphql/queries';
import { OrderState } from '../../graphql/types';
import auth from '../../lib/auth';
import { PaymentForm, PaymentType, labelPlacement } from '../../lib/enums';
import { paymentFormCZ, paymentTypeCZShorted } from '../../lib/localization';
import { Discount, OrderType, Payment, PaymentDialog } from '../../lib/types';
import {
  downloadPDF,
  getGraphqlErrorMessage,
  getIsOrder,
} from '../../lib/utils';
import ChangeDateDialog from './ChangeDateDialog';
import MissingPaymentsDialog from './MissingPaymentsDialog';
import Payments from './Payments';
import UnpaidPaymentDialog from './UnpaidPaymentDialog';
import './order.css';

const styles = () => ({
  textField: {
    margin: '16px',
    width: '85px',
  },
  button: {
    marginLeft: '16px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

const useStyles = makeStyles(styles);

interface IProps {
  orderId: number;
  open: boolean;
  handleClose: () => void;
  discounts?: any;
  order?: any;
  loadOrdersVariables: any;
}

const PriceDialog = ({
  orderId,
  open,
  handleClose,
  loadOrdersVariables,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [payments, setPayments] = useState<PaymentDialog[]>([
    {
      type: PaymentType.PRICE,
      amountToPay: 0,
      amount: -1,
      paid: 0,
      form: PaymentForm.CASH,
      buttonDisabled: false,
      readyPayments: [],
      readyForPay: 0,
      paymentTotal: 0,
    },
    {
      type: PaymentType.BAIL,
      amountToPay: 0,
      amount: -1,
      paid: 0,
      form: PaymentForm.CASH,
      buttonDisabled: false,
      readyPayments: [],
      readyForPay: 0,
      paymentTotal: 0,
    },
  ]);

  const { data: discountsData, loading: discountsLoading } = useQuery(
    discountsQuery,
  );

  const { data: orderData, loading: orderLoading } = useQuery(orderQuery, {
    variables: { id: orderId },
    fetchPolicy: 'network-only',
  });

  const { order } = orderData || {};
  const { discounts } = discountsData || {};

  const [withoutBailValue, setWithoutBailValue] = useState<boolean>(false);
  const [withoutBailModified, setWithoutBailModified] = useState<boolean>(
    false,
  );
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [bailTransfer, setBailTransfer] = useState<boolean>(false);
  const [dateChangeOpened, setDateChangeOpened] = useState<boolean>(false);
  const [unpaidPaymentOpened, setUnpaidPaymentOpened] = useState<boolean>(
    false,
  );
  const [morningPickUpState, setMorningPickUpState] = useState<boolean>(false);
  const [eveningReturnState, setEveningReturnState] = useState<boolean>(false);
  const [isOrder, setIsOrder] = useState<boolean>(false);
  const [isMissingPayments, setIsMissingPayments] = useState<boolean>(false);
  const [isPriceInEdit, setIsPriceInEdit] = useState<boolean>(false);
  const [editPriceAmount, setEditPriceAmount] = useState(0);
  const [isBailInEdit, setIsBailInEdit] = useState<boolean>(false);
  const [editBailAmount, setEditBailAmount] = useState(0);

  const getCreatedDate = (paymentType: PaymentType) => {
    if (order) {
      let date = moment();
      if (paymentType === PaymentType.BAIL && bailTransfer) {
        date = moment(order.from);
      }
      return date.format();
    }
    return '';
  };

  const [addPaymentsMutation] = useMutation(addPayments, {
    variables: {
      orderId: orderId,
      isUpdate: false,
      payments: payments?.flatMap(payment =>
        payment.readyPayments.map(readyPayment => ({
          amount: parseInt(readyPayment.amount.toString(), 10),
          type: readyPayment.type,
          form: readyPayment.form,
          createdAt: getCreatedDate(readyPayment.type),
        })),
      ),
    },
    onCompleted: () => {
      if (isOrder) {
        downloadPDF(orderId, true);
      }

      updateStateMutation();

      handleClose();
    },
    onError: error => {
      enqueueSnackbar(getGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  const [updateStateMutation] = useMutation(updateState, {
    variables: {
      orderId: orderId,
      state: isOrder ? OrderState.ACTIVE : OrderState.RESERVATION_PAID,
    },
    refetchQueries: [{ query: loadOrders, variables: loadOrdersVariables }],
  });

  const [updateBailMutation] = useMutation(updateOrderPriceOrBail, {
    variables: {
      orderId: orderId,
      amount: editBailAmount,
      valueType: 'bail',
    },
    refetchQueries: [
      {
        query: orderQuery,
        variables: {
          id: orderId,
        },
      },
    ],
    onCompleted: () => {
      setIsBailInEdit(false);
    },
  });

  const countEditPriceAmount = (amount: number) => {
    if (!order) {
      return 0;
    }
    let price = amount;
    if (
      order.price === 0 ||
      order.price === null ||
      order?.discount?.discount === 100
    ) {
      return amount;
    }
    if (order.discount) {
      let percentRest = (amount * 100) / order.price;
      if (percentRest === 100) {
        percentRest -= order.discount.discount;
      }

      price = Math.round(
        (order.price * percentRest) / (100 - order.discount.discount),
      );
    }
    return price;
  };

  const [updatePriceMutation] = useMutation(updateOrderPriceOrBail, {
    variables: {
      orderId: orderId,
      amount: countEditPriceAmount(editPriceAmount),
      valueType: 'price',
    },
    refetchQueries: [
      {
        query: orderQuery,
        variables: {
          id: orderId,
        },
      },
    ],
    onCompleted: () => {
      setIsPriceInEdit(false);
    },
  });

  const [setDiscountMutation] = useMutation(setDiscount, {
    variables: {
      discount: discountValue,
      orderId,
    },
  });

  const [morningPickupMutation] = useMutation(setMorningPickUp, {
    variables: {
      orderId,
      morningPickUp: morningPickUpState,
    },
    onCompleted(data) {
      setMorningPickUpState(!data.setMorningPickUp.morningPickUp);
    },
  });

  const handleMorningPickUpChange = () => {
    setMorningPickUpState(!morningPickUpState);
    morningPickupMutation();
  };

  const [eveningReturnMutation] = useMutation(setEveningRetrun, {
    variables: {
      orderId,
      eveningReturn: !eveningReturnState,
    },
    onCompleted(data) {
      setEveningReturnState(data.setEveningReturn.eveningReturn);
    },
  });

  const handleEveningReturnChange = () => {
    eveningReturnMutation();
  };

  const transactionSum = (payments: Payment[], type: PaymentType) => {
    return payments
      ?.map(payment => {
        if (payment.type === type) {
          return payment.amount;
        }
        return 0;
      })
      .reduce((acc, curr) => acc + curr, 0);
  };
  const orderLoaded = React.useRef(false);
  useEffect(() => {
    setIsOrder(order ? getIsOrder(order.state as any) : true);
    const propsOrder: OrderType = order as any;
    if (!order) {
      return;
    }
    setPayments(origPayments => {
      const paymentsTemp = [...origPayments];

      paymentsTemp[1].paid = transactionSum(
        propsOrder.payments,
        PaymentType.BAIL,
      );
      paymentsTemp[0].paid = transactionSum(
        propsOrder.payments,
        PaymentType.PRICE,
      );

      const bail = propsOrder.withoutBail ? 0 : order.bail;
      const price = Math.round(
        (propsOrder.price / 100) * (100 - propsOrder.discount.discount),
      );

      paymentsTemp[1].amountToPay = bail;
      paymentsTemp[0].amountToPay = price;
      paymentsTemp[1].readyForPay = bail - payments[1].paid;
      paymentsTemp[0].readyForPay = price - payments[0].paid;
      paymentsTemp[1].amount = propsOrder.withoutBail
        ? 0
        : bail - payments[1].paid;
      paymentsTemp[0].amount = price - payments[0].paid;

      for (const payment of paymentsTemp) {
        payment.buttonDisabled = payment.amountToPay - payment.paid <= 0;
      }

      return paymentsTemp;
    });

    setDiscountValue(propsOrder.discount.discount);
    if (!withoutBailModified) {
      setWithoutBailValue(propsOrder.withoutBail);
    }
    setEditBailAmount(propsOrder.bail);
    setEditPriceAmount(
      Math.round((order.price / 100) * (100 - order.discount.discount)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderId, open, auth]);

  useEffect(() => {
    if (!order || orderLoaded.current) {
      return;
    }
    orderLoaded.current = true;
    setMorningPickUpState(!order.morningPickUp);
    setEveningReturnState(order.eveningReturn);
  }, [order]);

  const handleDateChangeChange = () =>
    setDateChangeOpened(dateChangeOpened => !dateChangeOpened);

  const handleUnpaidPaymentChange = () =>
    setUnpaidPaymentOpened(unpaidPaymentOpened => !unpaidPaymentOpened);

  const handleIgnoreMissingPayments = async (confirm: boolean) => {
    setIsMissingPayments(false);

    if (confirm) {
      await addPaymentsMutation();
    }
  };

  const onButtonFinishClick = async () => {
    setIsOrder(order ? getIsOrder(order.state as any) : true);
    const isPreparedOrNew =
      order?.state === OrderState.RESERVATION_PREPARED ||
      order?.state === OrderState.RESERVATION_NEW;
    const hasUnpaidPayments = payments.some(payment => {
      const readyPaymentsTotal = payment.readyPayments.reduce(
        (sum, readyPayment) => sum + readyPayment.amount,
        0,
      );

      return (
        payment.amount === 0 &&
        payment.amountToPay !== payment.paid &&
        payment.buttonDisabled &&
        readyPaymentsTotal + payment.paid !== payment.amountToPay &&
        !(
          payment.type === PaymentType.BAIL &&
          (withoutBailValue || isPreparedOrNew)
        )
      );
    });

    const missingPayment = checkPayments(payments);
    if (missingPayment) {
      setIsMissingPayments(true);
      return;
    }

    if (discountValue !== 100 && hasUnpaidPayments) {
      handleUnpaidPaymentChange();
      return;
    }

    await addPaymentsMutation();
  };

  const onAmountChange = (index: number) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedPayments = [...payments];
    let amount = parseFloat(e.target.value);
    if (
      amount + updatedPayments[index].paymentTotal >
      updatedPayments[index].amountToPay
    ) {
      return;
    }

    if (!auth.isAdmin() && amount < 0) {
      amount = Math.abs(amount);
    }

    const reminder =
      updatedPayments[index].amountToPay - updatedPayments[index].paid;
    if (!auth.isAdmin() && amount > reminder) {
      amount = reminder;
    }

    updatedPayments[index] = {
      ...updatedPayments[index],
      amount: amount,
      readyForPay: amount,
    };
    setPayments(updatedPayments);
  };

  const onDiscountChange = (e: any) => {
    const discount = e.target.value;

    if (order) {
      const price = Math.ceil((order.price / 100) * (100 - discount));

      setPayments(prevPayments => {
        const updatedPayments = [...prevPayments];
        updatedPayments[0].amountToPay = price;
        updatedPayments[0].amount = price - updatedPayments[0].paid;
        updatedPayments[0].readyForPay = price;

        if (!auth.isAdmin()) {
          updatedPayments[0].buttonDisabled =
            updatedPayments[0].amountToPay - updatedPayments[0].paid <= 0;
        }
        return updatedPayments;
      });

      setDiscountValue(discount);
      // TODO: on false
      setDiscountMutation({
        variables: {
          discount: discount,
          orderId: orderId,
        },
      });
    }
  };

  const handleSelectBoxChange = (index: number, name: string) => (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const { value } = event.target;

    setPayments(prevPayments => {
      const mutPayments = [...prevPayments];
      mutPayments[index] = {
        ...mutPayments[index],
        [name]: value,
      };
      return mutPayments;
    });
  };

  const onWithoutBailChange = async () => {
    setWithoutBailModified(true);
    const paymentsMut = payments;
    if (order) {
      const bailPayment = paymentsMut.find(
        payment => payment.type === PaymentType.BAIL,
      );
      if (bailPayment && bailPayment.paid === 0) {
        bailPayment.amountToPay = !withoutBailValue ? 0 : order.bail;
        bailPayment.amount = !withoutBailValue ? 0 : order.bail;
        setPayments(paymentsMut);
        setWithoutBailValue(!withoutBailValue);
        // TODO: on false
        setDiscountMutation();
      }
    }
  };

  const sortDiscounts = (a: Discount, b: Discount) => {
    let result = 0;
    if (a.id < b.id) {
      result = -1;
    } else if (a.id > b.id) {
      result = 1;
    }
    return result;
  };

  const onBailTransferChange = () =>
    setBailTransfer(bailTransfer => !bailTransfer);

  const checkPayments = (arr: any) => {
    for (let i = 0; i < arr.length; i++) {
      const payment = arr[i];

      if (
        (withoutBailValue && payment.type === PaymentType.BAIL) ||
        payment.amountToPay === payment.paid
      ) {
        continue;
      }
      if (
        payment.paymentTotal + payment.paid !== payment.amountToPay ||
        !payment.buttonDisabled
      ) {
        return true;
      }
    }

    return false;
  };

  const handlePayments = (index: number) => {
    const updatedPayments = [...payments];
    const selectedAmount = parseInt(payments[index].amount.toString(), 10);

    updatedPayments[index].readyPayments?.push({
      amount: selectedAmount,
      form: updatedPayments[index].form,
      type: updatedPayments[index].type,
      inEditMode: false,
    });

    const remainingToPay =
      updatedPayments[index].amountToPay -
      selectedAmount -
      updatedPayments[index].paymentTotal -
      updatedPayments[index].paid;
    updatedPayments[index] = {
      ...updatedPayments[index],
      amount: remainingToPay,
      readyForPay: remainingToPay,
      paymentTotal: updatedPayments[index].paymentTotal + selectedAmount,
      buttonDisabled: remainingToPay === 0,
    };

    setPayments(updatedPayments);
  };

  const handlePaymentsUpdate = (updatedPayments: PaymentDialog[]) => {
    setPayments(updatedPayments);
  };

  const chceckBoxDisabled = order ? order.season : false;
  const color = isOrder ? 'primary' : 'secondary';

  const dataLoading = discountsLoading || orderLoading;

  return (
    <SnackbarProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {dataLoading ? (
          'Loading ..'
        ) : (
          <div>
            <DialogTitle id="form-dialog-title">Platba</DialogTitle>
            <DialogContent>
              <DialogContentText>Ceny</DialogContentText>
              <div className="prices">
                <div>
                  Kauce:{' '}
                  {order && order.bail ? order.bail.toLocaleString('FR') : 0}{' '}
                  CZK
                  {isBailInEdit ? (
                    <div className="directionRow">
                      <TextField
                        className={classes.textField}
                        style={{ margin: '5px' }}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        value={editBailAmount}
                        onChange={event => {
                          setEditBailAmount(parseInt(event.target.value));
                        }}
                      />
                      <Button
                        className={classes.button}
                        variant="contained"
                        color={color}
                        onClick={() => updateBailMutation()}
                      >
                        Uložit
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className="edit-icon"
                      onClick={() => setIsBailInEdit(true)}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </div>

                <div>
                  {!!order.discount.discount && (
                    <div>
                      Cena bez slevy: {order.price.toLocaleString('FR')}
                    </div>
                  )}
                  Půjčovné:{' '}
                  {order && order.price
                    ? Math.round(
                        (countEditPriceAmount(order.price) / 100) *
                          (100 - order.discount.discount),
                      ).toLocaleString('FR')
                    : 0}{' '}
                  CZK
                  {isPriceInEdit ? (
                    <div className="directionRow">
                      <TextField
                        className={classes.textField}
                        style={{ margin: '5px' }}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        value={editPriceAmount}
                        onChange={event => {
                          setEditPriceAmount(parseInt(event.target.value));
                        }}
                      />
                      <Button
                        className={classes.button}
                        variant="contained"
                        color={color}
                        onClick={() => updatePriceMutation()}
                      >
                        Uložit
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className="edit-icon"
                      onClick={() => setIsPriceInEdit(true)}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </div>
              </div>
              <Divider />
              <div className="checkBoxsGroup">
                <CheckBoxCustom
                  checked={withoutBailValue}
                  name="Bez kauce"
                  onChange={onWithoutBailChange}
                  labelPlacement={labelPlacement.START}
                  color={color}
                />
                {order && order.state !== OrderState.RESERVATION_PREPARED ? (
                  <CheckBoxCustom
                    checked={bailTransfer}
                    name="Převod kauce ze staré smlouvy"
                    onChange={onBailTransferChange}
                    labelPlacement={labelPlacement.START}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div>
                {discounts?.discounts && (
                  //SLEVA
                  <div className="checkBoxsGroup">
                    Sleva na půjčovné
                    <Select
                      className="selectBox"
                      value={discountValue}
                      color={color}
                      onChange={onDiscountChange}
                    >
                      {discounts.discounts
                        .sort(sortDiscounts)
                        .map((discount: any, index: number) => (
                          <MenuItem key={index} value={discount.discount}>
                            {`${discount.discount} %`}
                          </MenuItem>
                        ))}
                    </Select>
                    <Button
                      onClick={handleDateChangeChange}
                      className={classes.button}
                      variant="contained"
                      color={color}
                    >
                      Změnit termín
                    </Button>
                  </div>
                )}

                <div>
                  <CheckBoxCustom
                    checked={morningPickUpState}
                    name={'Nezapočítat první den (půjčeno po 12:00)'}
                    onChange={handleMorningPickUpChange}
                    labelPlacement={labelPlacement.END}
                    disabled={chceckBoxDisabled}
                    color={color}
                  />
                  <CheckBoxCustom
                    checked={eveningReturnState}
                    name={'Nezapočítat poslední den (vráceno do 12:00)'}
                    onChange={handleEveningReturnChange}
                    labelPlacement={labelPlacement.END}
                    disabled={chceckBoxDisabled}
                    color={color}
                  />
                </div>
                <div className="prices">
                  <div>
                    Kauce:{' '}
                    {payments[1].amountToPay
                      ? payments[1].amountToPay.toLocaleString('FR')
                      : 0}{' '}
                    CZK
                  </div>
                  <div>
                    Půjčovné:{' '}
                    {payments[0].amountToPay
                      ? payments[0].amountToPay.toLocaleString('FR')
                      : 0}{' '}
                    CZK
                  </div>
                </div>
                <Divider />
              </div>
              {payments
                .filter(
                  payment =>
                    (payment.type !== PaymentType.BAIL || !withoutBailValue) &&
                    payment.paid < payment.amountToPay,
                )
                ?.map((payment, index) => (
                  <div key={index} className="dialogRow">
                    <div className="paymentName">
                      {`${paymentTypeCZShorted[payment.type]}:`}
                    </div>
                    <div className="paymentName">
                      {`${(
                        payment.amountToPay -
                        payment.paymentTotal -
                        payment.paid
                      ).toLocaleString('FR')}`}
                    </div>
                    <div className="sign">{'+'}</div>
                    <div className="dialogCell">
                      <TextField
                        className={classes.textField}
                        type="number"
                        InputProps={{
                          inputProps: { min: 1, max: payment.amountToPay },
                        }}
                        disabled={payment.buttonDisabled}
                        value={payment.readyForPay}
                        onChange={onAmountChange(
                          payments.findIndex(
                            item => item.type === payment.type,
                          ),
                        )}
                      />
                    </div>
                    <div className="dialogCell">
                      <Select
                        className="selectBox"
                        value={payment.form}
                        disabled={payment.buttonDisabled}
                        onChange={handleSelectBoxChange(
                          payments.findIndex(
                            item => item.type === payment.type,
                          ),
                          'form',
                        )}
                        color={color}
                      >
                        {Object.keys(PaymentForm)
                          .filter(key => isNaN(Number(key)))
                          ?.map((paymentForm: string) => {
                            return (
                              <MenuItem value={paymentForm} key={paymentForm}>
                                {paymentFormCZ[paymentForm]}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                    <Button
                      className={classes.button}
                      disabled={payment.buttonDisabled}
                      variant="contained"
                      color={color}
                      onClick={() =>
                        handlePayments(
                          payments.findIndex(
                            item => item.type === payment.type,
                          ),
                        )
                      }
                    >
                      Zaplatit
                    </Button>
                  </div>
                ))}
              <></>
              <Payments
                payments={payments}
                onPaymentsUpdate={handlePaymentsUpdate}
              />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <div className="invisible">{}</div>
              <Button
                variant="contained"
                //disabled={finishButtonDisabled}
                onClick={onButtonFinishClick}
                color={color}
              >
                {isOrder ? 'Vydat a tisk faktury' : 'Potvrdit rezervaci'}
              </Button>
              <Button onClick={handleClose} color="primary">
                Zavřít
              </Button>
            </DialogActions>
            {dateChangeOpened && order && (
              <ChangeDateDialog
                order={order}
                open={dateChangeOpened}
                onClose={handleDateChangeChange}
              />
            )}
            {isMissingPayments && (
              <MissingPaymentsDialog
                open={isMissingPayments}
                onClose={handleIgnoreMissingPayments}
              />
            )}
            {unpaidPaymentOpened && (
              <UnpaidPaymentDialog
                open={unpaidPaymentOpened}
                onClose={handleUnpaidPaymentChange}
              />
            )}
          </div>
        )}
      </Dialog>
    </SnackbarProvider>
  );
};

export default PriceDialog;
